<template>
  <b-row class="my-1" no-gutters>
    <b-col>
      <div class="d-flex justify-content-between">
        <label style="margin-bottom: 0.9vw; margin-top: 0.9vw; font-weight: bold;">Client Information
          (recommended)</label>
        <span style="margin-bottom: 0.9vw; margin-top: 0.9vw; font-weight: bold;"
              variant="primary"
              v-tippy="{ placement : 'top',  arrow: true }" :content="'A minimum of 3 characters would activate the filter. \n\n'+
                              'If there are no records the information will be created, otherwise the information will be listed'">
                        <i class="material-icons">info</i>
                      </span>
      </div>
    </b-col>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" style="font-weight: bold">Name</span>
      </div>
      <vue-autosuggest
        style="width: 85%"
        :get-suggestion-value="getSuggestionValueName"
        :suggestions="[{data: list}]"
        v-model="client.name"
        :input-props="{placeholder: 'Search by name', class: 'form-control border-left-none'}"
        @input="keySearch"
        @selected="selectClient"
      >
        <template v-slot="{suggestion}">{{ suggestion.item.email }} - {{ suggestion.item.name }}
        </template>
      </vue-autosuggest>
    </div>
    <validation-provider rules="email" v-slot="{errors}" class="mb-3 w-100">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" style="font-weight: bold">E-mail</span>
        </div>
        <vue-autosuggest
          style="width: 85%"
          :get-suggestion-value="getSuggestionValueEmail"
          :suggestions="[{data: list}]"
          v-model="client.email"
          :input-props="{placeholder: 'Search by email.', class: 'form-control border-left-none'}"
          @input="keySearch"
          @selected="selectClient"
        >
          <template v-slot="{suggestion}">{{ suggestion.item.email }} - {{ suggestion.item.name }}
          </template>
        </vue-autosuggest>
      </div>
      <span class="text-right text-danger" v-if="errors.length">{{ $t(`vue_validator.${errors[0]}`) }}</span>
    </validation-provider>
  </b-row>
</template>

<script>
import {VueAutosuggest} from "vue-autosuggest";
import {get} from "@/services/api";

export default {
  name: "ClientSelect",
  components: {
    VueAutosuggest
  },
  props: {
    value: {
      type: Object,
      default: () => ({name: null, email: null})
    }
  },
  data: () => ({
    list: [],
    timer: null,
    client: {
      name: null,
      email: null,
    }
  }),
  methods: {
    getClients(e) {
      if (e.length >= 3)
        get(`subscription/filter/${e.toLowerCase()}`)
          .then(({data}) => {
              this.list = data.map(el => ({
                name: el.name.toLowerCase(),
                email: el.email.toLowerCase(),
              }))
            }
          )
    },
    selectClient({item}) {
      this.client = item;
    },
    keySearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getClients(e)
      }, 800);
    },
  },
  watch: {
    client: {
      handler() {
        this.$emit('input', this.client)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
