<template>
  <div>
    <div class="form-group" v-for="field of fields">
      <validation-provider rules="max:30" :name="field.label" v-slot="{errors}">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" style="font-weight: bold">{{ field.label }}</span>
          </div>
          <b-input v-model="model[field.field]"
                   class="form-control" :placeholder="field.placeholder" type="text"></b-input>
        </div>
        <div class="ml-auto" v-if="errors.length"><span class="text-danger">{{ errors[0] }}</span></div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
export default {
  name: "EcuTuneInfo",
  props: {
    value: {
      type: Object,
      default: () => ({tune: null, power: null, fuel: null, turbo: null, add: null})
    }
  },
  data: () => ({
    fields: [
      {label: 'Tune', placeholder: 'Enter tune brand & stage', field: 'tune'},
      {label: 'Power', placeholder: 'Enter power output (HP/Nm)', field: 'power'},
      {label: 'Fuel', placeholder: 'Enter fuel grade', field: 'fuel'},
      {label: 'Turbo', placeholder: 'Enter which turbo is installed', field: 'turbo'},
      {label: 'Add', placeholder: 'Enter additional modifications', field: 'add'}
    ],
    model: {
      tune: null,
      power: null,
      fuel: null,
      turbo: null,
      add: null,
    }
  }),
  mounted() {
    this.model = this.value ? this.value : this.model;
  },
  watch: {
    model: {
      handler() {
        this.$emit('input', this.model)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
