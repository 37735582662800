var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"my-1",attrs:{"no-gutters":""}},[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticStyle:{"margin-bottom":"0.9vw","margin-top":"0.9vw","font-weight":"bold"}},[_vm._v("Client Information (recommended)")]),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticStyle:{"margin-bottom":"0.9vw","margin-top":"0.9vw","font-weight":"bold"},attrs:{"variant":"primary","content":'A minimum of 3 characters would activate the filter. \n\n'+
                            'If there are no records the information will be created, otherwise the information will be listed'}},[_c('i',{staticClass:"material-icons"},[_vm._v("info")])])])]),_c('div',{staticClass:"input-group mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",staticStyle:{"font-weight":"bold"}},[_vm._v("Name")])]),_c('vue-autosuggest',{staticStyle:{"width":"85%"},attrs:{"get-suggestion-value":_vm.getSuggestionValueName,"suggestions":[{data: _vm.list}],"input-props":{placeholder: 'Search by name', class: 'form-control border-left-none'}},on:{"input":_vm.keySearch,"selected":_vm.selectClient},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var suggestion = ref.suggestion;
return [_vm._v(_vm._s(suggestion.item.email)+" - "+_vm._s(suggestion.item.name)+" ")]}}]),model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})],1),_c('validation-provider',{staticClass:"mb-3 w-100",attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",staticStyle:{"font-weight":"bold"}},[_vm._v("E-mail")])]),_c('vue-autosuggest',{staticStyle:{"width":"85%"},attrs:{"get-suggestion-value":_vm.getSuggestionValueEmail,"suggestions":[{data: _vm.list}],"input-props":{placeholder: 'Search by email.', class: 'form-control border-left-none'}},on:{"input":_vm.keySearch,"selected":_vm.selectClient},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var suggestion = ref.suggestion;
return [_vm._v(_vm._s(suggestion.item.email)+" - "+_vm._s(suggestion.item.name)+" ")]}}],null,true),model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1),(errors.length)?_c('span',{staticClass:"text-right text-danger"},[_vm._v(_vm._s(_vm.$t(("vue_validator." + (errors[0])))))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }