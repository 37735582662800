<template>
  <table class="table">
    <thead>
    <tr>
      <th>Order Number</th>
      <th>Date</th>
      <th>Type</th>
      <th v-if="previousOrderData.isOwner"></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{ previousOrderData.orderNumber }}</td>
      <td>{{ previousOrderData.date|localDateShort }}</td>
      <td>{{ previousOrderData.type|orderType }}</td>
      <td v-if="previousOrderData.isOwner">
                    <span style="cursor: pointer" @click="redirectToDetailOrder">  <i
                      class="material-icons">visibility</i></span>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {encrypt} from "@/helpers/encrypt";

export default {
  name: "TablePreviousOrder",
  props: {
    previousOrderData: {
      default: null
    }
  },
  methods: {
    redirectToDetailOrder() {
      const cryp = encrypt(JSON.stringify({id: this.previousOrderData.id}))
      this.$router.push({name: 'order-detail', params: {id: cryp}})
    },
  }
}
</script>

<style scoped>

</style>
