import Vue from 'vue';
import {get, post} from '@/services/api';
import {createProduct, dsgCreateProduct} from '@/services/endpoint.json';
import {onRequestPrice, URGENCY_NAMES} from '@/constants';
import {mapGetters, mapMutations, mapState} from 'vuex';
import VueTippy, {TippyComponent} from 'vue-tippy';
import DropDownSelect from '@/components/common/DropDownSelect';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import {VueAutosuggest} from "vue-autosuggest";
import TablePreviousOrder from "./TablePreviousOrder";
import OrigFile from "@/views/dashboard/create-software-order/options/OrigFile";
import CheckForCombo from "@/views/dashboard/create-software-order/options/CheckForCombo";
import ClientSelect from "@/views/dashboard/create-software-order-file/components/ClientSelect";
import EcuTuneInfo from "@/views/dashboard/create-software-order-file/components/EcuTuneInfo";
import {UserRole} from "@/utils/auth.roles";
import CreditsView from "@/components/common/CreditsView";

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

export default Vue.extend({
  name: 'detail',
  components: {
    DropDownSelect,
    CurrencyPriceView,
    VueAutosuggest,
    TablePreviousOrder,
    OrigFile,
    CheckForCombo,
    ClientSelect,
    EcuTuneInfo,
    CreditsView,
  },
  data: function () {
    return this.initialData();
  },
  computed: {
    ...mapGetters('geo', ['userCountryPrice', 'currencyToUser']),
    ...mapGetters('geo', ['currencyToUser']),
    ...mapState('orderSoftware', {
      params: state => state.params,
      preOrder: state => state.preOrden,
      vehicle: state => state.cart.vehicleId,
      arrayDsg: state => state.arrayDsg
    }),
    ...mapState('buySale', {
      information: state => state.information,
      credit_limit: state => state.information.allowedOrderNoBalance.limit,
      allowOrder: state => state.information.allowedOrderNoBalance
    }),
    ...mapGetters('orderSoftwareFile', ['reachedCreditLimit']),
    vinValue: {
      get() {
        return this.$store.state.orderSoftware.cart.vin;
      },
      set(value) {
        this.$store.commit('orderSoftware/setVehicleInfo', {vin: value});
      }
    },
    inputVinDisabled() {
      return this.$store.state.orderSoftware.cart.vehicleId !== null && this.$store.state.orderSoftware.cart.vehicleId.hasOwnProperty('ecu_code');
    },
    showBtnOrder() {
      const order_price = this.cart.description.price
      const order_pending = this.information.totalOrderSoftwareToPay
      const balance = this.information.balance
      const {status, limit} = this.information.allowedOrderNoBalance
      const permission = this.$checkSubUserPermission('order_software.create-order')
      if (!permission) {
        return false
      }
      if (this.$userCan(UserRole.Support)) {
        return true
      }
      if (status) {
        if (limit > 0)
          return order_pending < limit
        else
          return true
      } else {
        if (order_price === 9999) return balance > 0
        else return balance > order_price
      }
    },
    cannotCreateNoBalance() {
      const allow = this.information.allowedOrderNoBalance
      const totalOrdered = this.information.totalOrderSoftwareToPay
      const balance = this.information.balance
      const order_price = this.cart.description.price
      if (!allow.status) {
        if (order_price === 9999) return balance <= 0
        else return balance <= order_price
      }
      return false
    }
  },
  watch: {
    params: {
      deep: true,
      handler: function () {
        this.getData();
      }
    },
  },
  methods: {
    ...mapMutations('orderSoftware', ['switchSearchType']),
    initialData() {
      return {
        errorFile: false,
        dataForm: {
          answerExtraData: [],
          message: '',
          encrypt_files: true,
        },
        checks: [],
        attachedFiles: [],
        hasOptions: true,
        currentItem: '',
        currentOption: '',
        productExplanation: '',
        productDescription: '',
        type: '',
        selectedUrgency: '*',
        urgency: URGENCY_NAMES,
        showModal: false,
        showNewProduct: false,
        stage: '',
        currentEcu: {
          data: '',
          id: '',
          level: '',
          options: [],
          power: '',
          price: '',
          torque: '',
          type: '',
          vehicleId: '',
        },
        currentDsg: {
          data: '',
          id: '',
          level: '',
          options: [],
          power: '',
          price: '',
          torque: '',
          type: '',
          vehicleId: '',
        },
        sofware: [],
        sofwareMandatory: [],
        hardware: [],
        hardwareMandatory: [],
        dsgHardwareOptions: [],
        dsgOptions: [],//software optionals dsg
        dsgHardwareXoptions: [], //hardware required dsg
        preSelectedDsgOptions: [], //software required dsg
        cart: {
          description: {
            urgency: '',
            price: 0,
            client: {
              name: null,
              email: null,
            },
            ecu_info: null,
            note: null
          },
          item: []
        },
        arrayIdOptionUncheked: [],
        validateUncheked: false,
        showAddProductError: false,
        errorEcuInformation: false,
        temporalModel: null,
        inputSearchValue: '',
        arrayClients: [],
        showOverlay: false,
        showErrorPreviousOrder: false,
        previousOrderData: null,
        showErrorDifferentCars: false,
        vinDataValidation: null,
        allowExtensions: ['', 'text/plain', 'application/pdf', 'image/jpeg', 'application/x-rar-compressed', 'application/zip', 'audio/x-mod',
          'application/pkix-cert', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        ignoreVinCheck: {
          status: false,
          comment: null,
          verified: false
        },
        showErrorVinCheck: false,
        errorOnVerification: false,
        waitTimeOut: null
      };
    },
    processFile(e) {
      const files = [...e.target.files];
      this.$refs.file.type = 'text';
      this.$refs.file.type = 'file';
      for (const element of files) {
        if (this.attachedFiles.find(file => file.name === element.name && file.size === element.size)) {
          this.$swal({
            title: 'File already uploaded',
            text: `You have already uploaded ${element.name} file`,
            type: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#d14343',
          });
          return;
        }
        if (this.allowExtensions.includes(element.type)) {
          if (element.size <= (1024 * 1024 * 10)) {
            this.errorFile = false;
            this.attachedFiles.push(element);
          } else {
            this.$swal({
              title: 'Error',
              text: this.$t('form.file_size_error_10')
                .toString(),
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#d14343',
            }).then();
          }
        } else {
          this.$swal({
            title: 'Error',
            text: `${element.name} is not a permitted file`,
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
          }).then();
        }
      }
    },
    resetWindow() {
      Object.assign(this.$data, this.initialData());
    },
    async proceedToOrder() {
      const existSoftwareUnselected = this.sofwareMandatory.filter((el) => {
        const itemInCart = this.cart.item.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });
      const existHardwareUnselected = this.hardwareMandatory.filter((el) => {
        const itemInCart = this.cart.item.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });

      const existDsgPreUnselected = this.preSelectedDsgOptions.filter((el) => {
        const itemInCart = this.cart.item.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });

      const dsgHardwareXoptions = this.dsgHardwareXoptions.filter((el) => {
        const itemInCart = this.cart.item.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });
      this.sofwareMandatory = this.sofwareMandatory.map(el => {
        return {
          ...el,
          class: existSoftwareUnselected.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      this.hardwareMandatory = this.hardwareMandatory.map(el => {
        return {
          ...el,
          class: existHardwareUnselected.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      this.preSelectedDsgOptions = this.preSelectedDsgOptions.map(el => {
        return {
          ...el,
          class: existDsgPreUnselected.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      this.dsgHardwareXoptions = this.dsgHardwareXoptions.map(el => {
        return {
          ...el,
          class: dsgHardwareXoptions.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      if (existSoftwareUnselected.length || existHardwareUnselected.length || dsgHardwareXoptions.length) {
        await this.$swal({
          title: 'Error',
          text: "Required option(s) weren't selected. Make sure the options are selected and try again.",
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#d14343',
          customClass: {
            container: 'my-swal'
          }
        });
        if (this.$refs.org_file) {
          this.$refs.org_file.setAsError();
        }
      } else {
        this.$refs.order.show();
      }
    },
    handleClose() {
      this.$refs.addProduct.hide();
      this.resetOption(this.currentItem);
    },
    resetOption(item) {
      item.class = '';
      const index = this.cart.item.findIndex(el => el.id == item.id && el.location == item.location);
      if (index > -1) {
        if (item.required) {
          this.cart.item[index].options = JSON.parse(JSON.stringify(item.options));
        } else {
          this.cart.item.splice(index, 1);
        }
      }
      const indexInArray = this[item.location].findIndex(el => el.id == item.id);
      this[item.location][indexInArray].icon = 'add';
      this.$set(this[item.location], indexInArray, this[item.location][indexInArray]);
      this.$refs[`${item.location}-${item.id}`][0].textSelected = 'Select';
      this.$refs[`${item.location}-${item.id}`][0].setPreselected();
    },
    getData() {
      this.resetWindow();
      switch (this.params.type) {
        case 'ECU':
          this.type = 'ECU';
          this.currentEcu = JSON.parse(JSON.stringify(this.$store.getters['orderSoftware/getEcu'](this.params.id)));
          this.cart.description.original_price = this.currentEcu.price;
          this.cart.description.price = this.$store.getters['geo/userCountryPrice'](this.currentEcu.price);
          if (isNaN(this.cart.description.price)) {
            this.cart.description.price = onRequestPrice;
          }
          if (this.currentEcu) {
            this.formatEcu();
            this.cart.item = JSON.parse(JSON.stringify([].concat(this.sofwareMandatory, this.hardwareMandatory)));
          } else {
            this.$router.push({name: 'order-software'});
          }
          break;
        case 'ECU + DSG':
          const combo = JSON.parse(JSON.stringify(this.$store.getters['orderSoftware/getCombo'](this.params.id)));
          if (combo) {
            this.type = 'ECU + DSG';
            this.currentEcu = combo.ecuObject;
            this.currentDsg = combo.dsgObject;
            this.cart.description.original_price = combo.price;
            this.cart.description.price = this.$store.getters['geo/userCountryPrice'](combo.price);
            if (isNaN(this.cart.description.price)) {
              this.cart.description.price = onRequestPrice;
            }
            this.formatEcu();
            this.formatDSG();
            this.cart.item = JSON.parse(JSON.stringify([].concat(this.sofwareMandatory, this.hardwareMandatory, this.preSelectedDsgOptions, this.dsgHardwareXoptions)));
          } else {
            this.$router.push({name: 'order-software'});
          }
          break;
        case 'DSG':
          this.type = 'DSG';
          this.currentDsg = JSON.parse(JSON.stringify(this.$store.getters['orderSoftware/getDsg'](this.params.id)));
          this.formatDSG();
          this.cart.item = JSON.parse(JSON.stringify(this.preSelectedDsgOptions.concat(this.dsgHardwareXoptions)));
          this.cart.description.original_price = this.currentDsg.price;
          this.cart.description.price = this.$store.getters['geo/userCountryPrice'](this.currentDsg.price);
          if (isNaN(this.cart.description.price)) {
            this.cart.description.price = onRequestPrice;
          }
      }
      this.setStatusFromPreOrder();
      this.checkChoices();
    },
    setStatusFromPreOrder() {
      if (this.preOrder) {
        this.preOrder.items.forEach(item => {
          let itemIncomponent = [].concat(this.sofware, this.hardware, this.sofwareMandatory, this.hardwareMandatory, this.preSelectedDsgOptions, this.dsgOptions, this.dsgHardwareOptions, this.dsgHardwareXoptions)
            .find(el => el.id === item.id && el.location === item.location);
          if (itemIncomponent) {
            itemIncomponent.class = 'font-weight-bold';
            let index = this[itemIncomponent.location].findIndex(el => el.id === itemIncomponent.id && el.location === itemIncomponent.location);
            if (Array.isArray(itemIncomponent.options) && itemIncomponent.options.length) {
              this.selectSecondaryOption(item, item.options[0].id, index);
              itemIncomponent.icon = 'check';
              itemIncomponent.option_selected = item.options[0].id;
            } else {
              itemIncomponent.checked = true;
              if (['sofware', 'hardware', 'dsgOptions'].includes(itemIncomponent.location)) {
                // this.determineControl(item);
              }
            }
            this.$set(this[itemIncomponent.location], index, itemIncomponent);
          }
        });
        this.checkChoices();
      }
    },
    formatDSG() {
      this.currentDsg.hardwareOptions.forEach(item => {
        item.icon = 'add';
        item.dest = 'DSG';
        item.option_selected = null;
        if (item.add_new_one) {
          item.options.push({
            id: 'addProduct',
            description: 'Add new product or value',
            class: 'font-italic'
          });
        }
        if (item.default_option) {
          item.option_preselected = JSON.parse(item.default_option);
        }
        if (item.required) {
          item.location = 'dsgHardwareXoptions';
          item.option_preselected = [];
          this.dsgHardwareXoptions.push(item);
        } else {
          item.location = 'dsgHardwareOptions';
          this.dsgHardwareOptions.push(item);
        }
      });
      this.currentDsg.options.forEach((item) => {
        item.icon = 'add';
        item.dest = 'DSG';
        item.option_selected = null;
        if (item.add_new_one) {
          item.options.push({
            id: 'addProduct',
            description: 'Add new product or value',
            class: 'font-italic'
          });
        }
        if (item) {
          if (item.default_option) {
            item.option_preselected = JSON.parse(item.default_option);
          }
          if (item.required === true) {
            item.location = 'preSelectedDsgOptions';
            item.option_preselected = [];
            this.preSelectedDsgOptions.push(item);
          } else {
            item.location = 'dsgOptions';
            this.dsgOptions.push(item);
          }
        }
      });
    },
    formatEcu() {
      this.currentEcu.options.forEach((item) => {
        if (item) {
          item.dest = 'ECU';
          if (item.add_new_one) {
            item.options.push({
              id: 'addProduct',
              description: 'Add new product or value',
              class: 'font-italic'
            });
          }
          if (item.default_option) {
            item.option_preselected = JSON.parse(item.default_option);
          }
          if (Array.isArray(item.options) && item.options.length) {
            item.icon = 'add';
            item.option_selected = null;
          }

          if (item.type.toString() === 'engine_software' && item.required === false) {
            item.location = 'sofware';
            if (item.id == 12) {
              this.sofware.unshift(item);
            } else {
              this.sofware.push(item);
            }
          }
          if (item.type.toString() === 'engine_software' && item.required === true) {
            item.location = 'sofwareMandatory';
            item.option_preselected = [];
            if (item.id == 12) {
              this.sofwareMandatory.unshift(item);
            } else {
              this.sofwareMandatory.push(item);
            }
          }
          if (item.type.toString() === 'hardware_modification' && item.required === false) {
            item.location = 'hardware';
            this.hardware.push(item);
          }
          if (item.type.toString() === 'hardware_modification' && item.required === true) {
            item.location = 'hardwareMandatory';
            item.option_preselected = [];
            this.hardwareMandatory.push(item);
          }
        }
      });
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    async addproduct() {
      if (this.productDescription.length) {
        let query = {
          optionId: this.currentItem.id,
          type: this.currentItem.type,
          description: this.productDescription,
          explanation: this.productExplanation
        };
        const dest = this.currentItem.dest == 'ECU' ? createProduct : dsgCreateProduct;
        const {data} = await post(dest, query, true);

        if (data.statusCode == '200') {
          let newElement = {
            description: `(NEW) ${this.productDescription}`,
            explanation: this.productExplanation,
            type: this.currentItem.type,
            id: data.id,
          };
          this.currentItem.options.push(newElement);
          this.currentItem.icon = 'check';
          this.currentItem.option_selected = newElement.id;
          const index = this[this.currentItem.location].findIndex(el => el.id == this.currentItem.id);
          this.$set(this[this.currentItem.location], index, this.currentItem);
          await this.selectSecondaryOption(this.currentItem, newElement.id, index);
          this.showNewProduct = false;
          this.currentItem = null;
          await this.showAlert('New product saved', 'success');
          this.$refs.addProduct.hide();
          this.productDescription = '';
          this.productExplanation = '';
        }
      } else {
        this.showAddProductError = true;
      }
    },
    async selectSecondaryOption(item, option, index) {
      const saveditem = JSON.parse(JSON.stringify(item));
      if (item.save_price !== undefined) {
        this.cart.description.price = item.save_price;
      }
      if (item.required) {
        saveditem.class = '';
        if (!option) {
          saveditem.class = 'color-red';
        }
      }
      if (!isNaN(option) && option) {
        item.icon = 'check';
        const optionSlected = item.options.find(el => el.id == option);
        item.option_selected = optionSlected.id;
        const copyItem = JSON.parse(JSON.stringify(item));
        copyItem.options = [optionSlected];
        let indexOnItems = this.cart.item.findIndex(el => {
          return (el.id === item.id && el.location === item.location);
        });
        let confirmAdd = true;
        if (optionSlected.extradata) {
          optionSlected.extraValue = '';
          optionSlected.extradata = (typeof optionSlected.extradata == 'string') ? JSON.parse(optionSlected.extradata) : optionSlected.extradata;
          const extraValue = await this.showExtraInput(optionSlected.extradata, this.userCountryPrice(copyItem.new_price));
          if (extraValue !== false) {
            optionSlected.extraValue = extraValue;
            const optionSelected = optionSlected;
            const result = extraValue;
            let text = optionSlected.extradata.on_result.description;
            const regexp = /\{(.*?)\}/g
            const found = {};
            let array1;
            while ((array1 = regexp.exec(text)) !== null) {
              found[array1[1]] = array1[0]
            }
            Object.keys(found).forEach(value => {
              let [object, field] = value.split('.');
              text = text.replace(found[value], eval(`${object}[field]`))
            })
            optionSlected.description = text;
            item.save_price = this.cart.description.price;
            this.cart.description.price = 9999;
            this.$refs[`${item.location}-${item.id}`][0].textSelected = `${text}`;
          } else {
            item.icon = 'add';
            this.$refs[`${item.location}-${item.id}`][0].setPreselected();
            confirmAdd = false;
            return;
          }
        }
        if (copyItem.new_price !== null && copyItem.new_price !== undefined && copyItem.new_price !== '') {
          item.save_price = this.cart.description.price;
          if (isNaN(copyItem.new_price)) {
            this.cart.description.price = onRequestPrice
          } else {
            this.cart.description.price = this.userCountryPrice(copyItem.new_price);
          }
        }
        if (optionSlected.new_price !== null && optionSlected.new_price !== undefined && optionSlected.new_price !== '') {
          item.save_price = this.cart.description.price;
          if (isNaN(optionSlected.new_price)) {
            this.cart.description.price = onRequestPrice
          } else {
            this.cart.description.price = this.userCountryPrice(optionSlected.new_price);
          }
        }
        if (indexOnItems > -1 && confirmAdd) {
          this.cart.item = JSON.parse(JSON.stringify(this.cart.item.map(el => {
            if (el.id === item.id && el.location == item.location) {
              el.options = [optionSlected];
              return el;
            }
            return el;
          })));
        } else if (confirmAdd) {
          this.cart.item.push(copyItem);
        }
      } else if (!option) {
        item.icon = 'add';
        let indexOnItems = this.cart.item.findIndex(el => {
          return (el.id === item.id && el.location === item.location);
        });
        if (indexOnItems > -1) {
          this.cart.item.splice(indexOnItems, 1);
        }
      } else if (option === 'addProduct') {
        this.$refs.addProduct.show();
        this.showAddProductError = false;
        this.currentItem = item;
      }
      if (item.options.length !== saveditem.options.length) {
        item.options = saveditem.options;
      }
      this.$set(this[item.location], index, item);
      this.checkChoices();
    },
    async determineControl(item) {
      const indexLocation = this[item.location].find((el) => el.id === item.id);
      let option = this.cart.item.findIndex(el => el.id == item.id && el.location === item.location);
      if (option > -1) {
        item.checked = false;
        this.cart.item.splice(option, 1);
        if (item.new_price && this.cart.description.price > 0) {
          this.cart.description.price -= item.new_price;
        }
      } else {
        item.checked = true;
        if (item.new_price) {
          await new Promise(resolve => {
            this.$swal({
              title: 'Information',
              icon: 'question',
              text: `${item.name} is available for an extra fee of ${item.new_price} credits.`,
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000',
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              showCancelButton: true
            }).then((value) => {
              if (value.isConfirmed) {
                this.cart.item.push(item);
                if (this.cart.description.price > 0) {
                  this.cart.description.price += Number(item.new_price);
                }
              } else {
                item.checked = false;
              }
              resolve(true)
            })
          })
        } else {
          this.cart.item.push(item);
        }
      }
      this.$set(this[item.location], indexLocation, item);
      this.checkChoices();
    },
    checkout() {
      this.cart.type = this.type;
      this.cart.description.vin_verified = (this.inputVinDisabled) ? true :
        this.ignoreVinCheck.verified ? true : this.ignoreVinCheck.comment;
      this.cart.urgency = this.selectedUrgency;
      this.$store.commit('orderSoftware/files', this.attachedFiles);
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.$router.push({name: 'checkout'});
    },
    checkChoices() {
      setTimeout(() => {
        this.dsgOptions = this.dsgOptions.map((option) => this.manegeChoiseRestriction(option));
        this.preSelectedDsgOptions = this.preSelectedDsgOptions.map((option) => this.manegeChoiseRestriction(option));

        this.hardwareMandatory = this.hardwareMandatory.map(option => this.manegeChoiseRestriction(option))
        this.hardware = this.hardware.map(option => this.manegeChoiseRestriction(option))
      }, 100);
    },
    manegeChoiseRestriction(option) {
      if (option.choices) {
        if (option.choices.enable) {
          let nowDisable = this.comproveIfCumple(option, option.choices.enable, option.choices.model_restriction, false, option.location);
          if (nowDisable) {
            this.removeFromSelected(option);
          } else if (option.required && !this.cart.item.find(el => el.option_id == option.option_id && el.location == option.location)) {
            this.cart.item.push(option);
          }
          option.disabled = nowDisable;
        }
        if (option.choices.disable) {
          let nowDisable = this.comproveIfCumple(option, option.choices.disable, option.choices.model_restriction, true, option.location);
          if (nowDisable) {
            this.removeFromSelected(option);
          } else if (option.required && !this.cart.item.find(el => el.option_id == option.option_id && el.location == option.location)) {
            this.cart.item.push(option);
          }
          option.disabled = nowDisable;
        }
      }
      return option;
    },
    removeFromSelected(option) {
      let index = this.cart.item.findIndex(el => el.option_id == option.option_id && el.location == option.location);
      if (index > -1) {
        this.cart.item.splice(index, 1);
        option.icon = 'add';
        if (Array.isArray(option.options) && option.options.length) {
          this.resetOption(option);
        }
      }
    },
    comproveIfCumple(option, restriction, model_restriction, returnValue, location) {
      let arrayOptions = [].concat(this.dsgOptions, this.preSelectedDsgOptions);
      if (['hardware', 'hardwareMandatory'].includes(location)) {
        arrayOptions = [].concat(this.hardware, this.hardwareMandatory)
      }
      const optionFound = arrayOptions
        .find(el => el.option_id == restriction.option_id);
      if (model_restriction === undefined || model_restriction.includes(this.vehicle.dsg)) {
        if (optionFound && restriction.type_rel == 'boolean') {
          return optionFound.checked.toString() == restriction.value_expected ? returnValue : !returnValue;
        } else {
          return !returnValue;
        }
      } else {
        return returnValue;
      }
    },
    async showExtraInput(options, newPrice) {
      const inputs = [];
      options.inputs.forEach(el => {
        inputs.push(`
        <div>${el.label}</div>
        <input class="swal2-input text-uppercase" name="${el.field}" id="${el.field}" maxlength="${el.maxlength}" minlength="${el.minlength}"/>
        `);
      });
      if (options.subtitle) {
        inputs.push(`${options.subtitle}`);
      }
      const value = await this.$swal({
        title: options.title,
        text: options.subtitle,
        showConfirmButton: true,
        showCancelButton: true,
        html: inputs.join('\n'),
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        preConfirm: () => {
          const values = {};
          options.inputs.forEach(el => {
            values[el.field] = document.getElementById(el.field).value;
          });
          let isInvalid = false;
          Object.keys(values)
            .forEach(k => {
              if (values[k] == '') {
                isInvalid = true;
              }
            });
          if (!isInvalid) {
            return values;
          } else {
            this.$swal.showValidationMessage('Enter a value in the field(s)');
          }
        }
      });
      if (value.isConfirmed) {
        return value.value;
      } else {
        return false;
      }
    },
    validateLength(value, length, id) {
      const x = document.getElementById(id);
      if (value.length > length) {
        x.style.display = "block";
        if (value.length > length + 5) {
          this[id] = null
        }
      } else {
        x.style.display = "none";
      }
    },
    getSuggestionValueName(suggestion) {
      return suggestion.item.name;
    },
    getSuggestionValueEmail(suggestion) {
      return suggestion.item.email;
    },
    keySearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getClients(e)
      }, 800);
    },
    selectClient(option) {
      this.clientMail = '';
      this.clientName = '';
      this.clientMail = option.item.email.toLowerCase();
      this.clientName = option.item.name.toLowerCase();
    },
    async getClients(e) {
      if (e.length >= 3) {
        const {data} = await get(`subscription/filter/${e.toLowerCase()}`)
        this.arrayClients = data.map(x => ({name: x.name.toLowerCase(), email: x.email.toLowerCase()}))
      }
    },
    async checkOrder() {
      if (this.errorOnVerification) {
        this.$bvModal.show("modal-vin-no-confirmed");
        return;
      }
      this.showErrorDifferentCars = false
      this.showErrorPreviousOrder = false
      this.showOverlay = true
      const data = await this.findPreviousOrder();
      this.showErrorPreviousOrder = !!data;
      if (!!data) {
        this.previousOrderData = {
          orderNumber: data.orderNumber,
          date: data.date,
          price: data.price,
          status: data.status,
          type: data.type,
          id: data.id,
          isOwner: data.isOwner
        }
        this.hiddenOverlay()
        return;
      }
      const result = await this.$refs.checkForCombo.checkForCombo(this.vinValue);
      if (result) {
        this.$store.commit('orderSoftware/setParameters', {
          type: result.type,
          id: result.id,
        });
        this.$refs.order.hide();
        this.$refs.order.$once('hidden', () => {
          setTimeout(() => this.scrollTo('optionsP'), 100)
        });
        return;
      } else if (result === null) {
        this.showOverlay = false
        return;
      }
      if (this.$store.state.orderSoftware.manualSearch || this.$store.state.orderSoftware.manualSearch === undefined) {
        if (!this.ignoreVinCheck.status) {
          if (this.vinValue.length === 17) {
            this.searchVin(this.vinValue)
          } else {
            this.showOverlay = false
            this.$bvModal.show("modal-vin-no-confirmed")
            // this.checkout();
          }
        } else {
          this.showErrorDifferentCars = true
          if (this.ignoreVinCheck.comment !== null && this.ignoreVinCheck.comment !== '' && this.ignoreVinCheck.comment.length <= 250) {
            this.checkout();
          } else {
            this.showErrorVinCheck = true
            this.hiddenOverlay()
          }
        }
      } else {
        this.hiddenOverlay();
        this.checkout();
      }
    },
    async findPreviousOrder() {
      return new Promise(async resolve => {
        const {data} = await post('order-software/find-order-new', {vin: this.vinValue}, true)
        resolve(data)
      })
    },
    searchVin() {
      post('site/find-by-vin', {vin: this.vinValue.toUpperCase()}).then((() => {
        this.waitResponse()
      })).catch(() => {
        this.errorOnVerification = true;
        this.$bvModal.show("modal-vin-no-confirmed")
        this.showOverlay = false;
      });
    },
    waitResponse() {
      this.waitTimeOut = setTimeout(() => {
        this.sockets.unsubscribe(this.vinValue.toUpperCase())
        this.errorOnVerification = true;
        this.$bvModal.show("modal-vin-no-confirmed")
        this.showOverlay = false;
      }, 25 * 1000)

      this.sockets.subscribe(this.vinValue.toUpperCase(), async (response) => {
        if (this.waitTimeOut) {
          clearTimeout(this.waitTimeOut);
        }
        const cartMake = this.$store.state.orderSoftware.cart.vehicleId.brand.slice(0, 2).toLowerCase();
        const cartModel = this.$store.state.orderSoftware.cart.vehicleId.model.slice(0, 2).toLowerCase();
        const vehicleId = this.$store.state.orderSoftware.cart.vehicleId.id;
        if (response.result) {
          this.vinDataValidation = {
            make: response.result.make,
            model: response.result.model,
          }
          if (response.result && response.result.vehicles && response.result.vehicles.includes(vehicleId)) {
            this.ignoreVinCheck.verified = true;
            this.checkout();
          } else {
            const robotMake = response.result.make.slice(0, 2).toLowerCase();
            const robotModel = response.result.model.slice(0, 2).toLowerCase();
            this.showErrorDifferentCars = !((cartMake === robotMake) && (cartModel === robotModel));
            if (!this.showErrorDifferentCars) {
              this.ignoreVinCheck.verified = true;
              this.checkout();
            }
            this.hiddenOverlay()
          }
        } else { // find in previous orders
          let {data} = await post('order-software/find-order-completed-vin', {vin: this.vinValue})
          if (data) {
            data = JSON.parse(data.vehicle)
            const resModel = data.model.slice(0, 2).toLowerCase()
            const resMake = data.brand.slice(0, 2).toLowerCase()
            this.showErrorDifferentCars = !((cartMake === resMake) && (cartModel === resModel));
            this.hiddenOverlay()
            if (!this.showErrorDifferentCars) {
              this.ignoreVinCheck.verified = true;
              this.checkout();
            }
            this.vinDataValidation = {
              make: data.brand,
              model: data.model
            }
          } else {
            this.hiddenOverlay()
            this.$bvModal.show("modal-vin-no-confirmed");
            this.showErrorDifferentCars = false
          }
        }
      });
    },
    redirectTo() {
      window.open('https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+terms+and+condition.pdf', '_blank')
    },
    hiddenOverlay() {
      setTimeout(() => {
        this.showOverlay = false
      }, 2000)
    },
    setPriceFromOrigFile(price) {
      this.cart.description.price = price
    },
    ready(item) {
      let index = this.cart.item.findIndex(el => el.id === item.id);
      if (index !== -1) {
        this.cart.item.splice(index, 1);
      }
      this.cart.item.push(item);
    },
    closeModalVIN() {
      this.errorOnVerification = false;
      this.$bvModal.hide("modal-vin-no-confirmed")
      this.showOverlay = false;
    },
    scrollTo(element) {
      document.getElementById(element)
        .scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
    }
  },
})
;




