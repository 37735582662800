<template>
  <b-modal v-model="active" hide-footer title="Attention!" centered body-class="p-3" dialog-class="modal-custom-sm"
           title-class="text-dark" no-close-on-backdrop no-close-on-esc @close="close">
    <template v-if="lastOrder">
      <h6 class="text-center mb-3 text-dark">
        Already a TVS order purchased for this VIN and TVS upgrade policy applicable.
      </h6>
      <p class="text-dark p-0 my-1" v-if="lastOrder">
        Previous order Nr.
        <router-link style="text-decoration: underline" target="_blank"
                     :to="{name: 'order-detail', params: {id: getIdParam()}}">
          {{ lastOrder.orderId }} / {{ lastOrder.stage | stageType(lastOrder.type) }}
        </router-link>
      </p>
      <p class="text-dark my-3 text-center color-gray">
        Select a Combo to apply the TVS upgrade policy. Recommended combos:
      </p>
      <b-list-group class="border-0 text-dark">
        <b-list-group-item v-for="combo of availableCombos" class="d-flex justify-content-between py-1"
                           style="line-height: 2.2" :key="combo.id">
      <span>
        ECU {{ combo.ecu.replace('.1', '+') }}
      </span>
          <span> + </span>
          <span class="d-flex justify-content-between">
        DSG {{ combo.dsg.replace('.1', '+') }}
        <span class="ml-3"><b-button size="sm" class="py-1" @click="selectCombo(combo)" variant="dark"
                                     v-tippy="{ placement : 'top',  arrow: true }"
                                     content="Use this combo to apply upgrade policy.">
          <i class="fa fa-check"></i>
        </b-button></span>
      </span>
        </b-list-group-item>
      </b-list-group>

      <div class="text-danger m-2 text-center">
        <p>
          If not combo is selected, previous order will not be refunded.
        </p>
        <b-button variant="danger" size="sm" @click="resolveFn">Continue without price policy</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import {post} from "@/services/api";
import moment from "moment";
import {encrypt} from "@/helpers/encrypt";

export default {
  name: "CheckForCombo",
  computed: {
    ...mapState('orderSoftware', {
      combos: state => state.combosTunning.combos,
      order: state => state.params,
    })
  },
  data: () => ({
    active: false,
    lastOrder: null,
    availableCombos: [],
    resolve: null
  }),
  methods: {
    async checkForCombo() {
      this.lastOrder = null
      return new Promise(async (resolve) => {
        this.resolve = resolve;
        await this.searchPreviousOrders();
        if (!(this.lastOrder && this.combos.length)) {
          this.resolveFn();
          return;
        }
        const now = moment();
        const diff = moment.duration(now.diff(this.lastOrder.createdAt));
        if (diff.asYears() < 3) {
          const previousOrderType = this.lastOrder.type;
          if ((previousOrderType.includes('+') && !this.order.type.includes('+')) || (previousOrderType !== this.order.type && !this.order.type.includes('+'))) {
            this.availableCombos = this.combos.filter(combo => {
              const field = this.order.type.toLowerCase()
              return combo[field] === this.order.id;
            });
            this.active = this.availableCombos.length > 0;
            if (!this.active) {
              this.resolveFn();
            }
            return;
          }
        }
        this.resolveFn();
      });
    },
    async searchPreviousOrders() {
      return post('order-software/find-history-to-upgrade', {vin: this.$store.state.orderSoftware.cart.vin}, true)
        .then(({data}) => {
          if (data.success) {
            this.lastOrder = {
              ...data.order,
              createdAt: moment(data.order.createdAt),
              description: JSON.parse(data.order.description),
            }
          }
        });
    },
    selectCombo(combo) {
      this.active = false;
      this.resolve(combo)
    },
    resolveFn() {
      this.active = false;
      this.resolve(false);
    },
    close() {
      this.resolve(null)
    },
    getIdParam() {
      return encrypt(JSON.stringify({id: this.lastOrder.id}))
    }
  }
}
</script>

<style>
.modal-custom-sm {
  max-width: 370px;
}
</style>
